<template>
  <div v-html="activityInfoData.description" class="description-dom"></div>
</template>

<script>
export default {
  props: {
    //活动信息数据
    activityInfoData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.description-dom {
  padding: 40px 0 30px 30px;
}
</style>
